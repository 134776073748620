import { SearchResponse, SortDirection } from '../types';

export const eqFilter = buildFilter('eq');
export const neFilter = buildFilter('ne');
export const ltFilter = buildFilter('lt');
export const gtFilter = buildFilter('gt');
export const lteFilter = buildFilter('lte');
export const gteFilter = buildFilter('gte');
export const likeFilter = buildFilter('like');
export const inFilter = buildFilter('in');
export const ninFilter = buildFilter('nin');

export function sortValue(field: string, direction: SortDirection) {
  if (direction === 'asc') {
    return ascSortField(field);
  } else {
    return descSortField(field);
  }
}

export function emptySearchResponse<T>(): SearchResponse<T> {
  return {
    total: 0,
    count: 0,
    results: [],
  };
}

function buildFilter(prefix: string) {
  return (value: string) => `${prefix}:${value}`;
}

function ascSortField(field: string) {
  return field;
}

function descSortField(field: string) {
  return `-${field}`;
}
