import {
  TableFooter,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import React, { MouseEvent, ChangeEvent, useState } from 'react';
import { isOdd, getDuration } from '../../utils';
import { Column, RowData } from './types';
import { SortDirection } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      overflowX: 'auto',
    },
    rowError : {
      color: `${theme.palette.warning.contrastText} !important`,
      backgroundColor: `${theme.palette.warning.main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.warning.light} !important`,
      },
    },
    cellError : {
      color: `${theme.palette.error.contrastText}`,
      backgroundColor: `${theme.palette.error.main}`,
      '&:hover': {
        backgroundColor: `${theme.palette.error.light} !important`,
      },
    },
    table: {
      minWidth: 650,
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    selectedRow: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
      },
    },
    plainTableRow: {
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
      },
    },
    highlightedTableRow: {
      backgroundColor: grey[50],
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
      },
    },
    spacer: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export type TableProps = {
  columns: Column[];
  rows: RowData[][];
  total: number;

  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;

  rowsPerPage: number;
  onChangeRowsPerPage: (rowsPerPage: number) => void;

  sortDirection: SortDirection;
  onChangeSortDirection: (sortDirection: SortDirection) => void;

  sortField: Column;
  onChangeSortField: (sortField: Column) => void;
};

export default function Table({
  columns,
  rows,
  total,
  currentPage,
  onChangeCurrentPage,
  rowsPerPage,
  onChangeRowsPerPage,
  sortDirection,
  onChangeSortDirection,
  sortField,
  onChangeSortField,
}: TableProps) {
  async function handlePageChange(_: any, page: number) {
    await onChangeCurrentPage(page);
  }

  async function handleRowsPerPageChange(event: ChangeEvent<HTMLInputElement>) {
    await event.preventDefault();
    onChangeRowsPerPage(Number(event.target.value) || 25);
  }

  async function handleSortDirectionChange(sortDirection: SortDirection) {
    await onChangeSortDirection(sortDirection);
  }

  async function handleSortFieldChange(sortField: Column) {
    await onChangeSortField(sortField);
  }

  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <MuiTable className={classes.table}>
        <TableHead>
          <MuiTableRow>
            <TableHeaders
              columns={columns}
              sortDirection={sortDirection}
              onChangeSortDirection={handleSortDirectionChange}
              sortField={sortField}
              onChangeSortField={handleSortFieldChange}
            />
          </MuiTableRow>
        </TableHead>
        <TableBody>
          <TableRows columns={columns} rows={rows} />
        </TableBody>
        <TableFooter>
          <MuiTableRow>
            <TablePagination
              colSpan={12}
              count={total}
              page={currentPage}
              onChangePage={handlePageChange}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </MuiTableRow>
        </TableFooter>
      </MuiTable>
    </Paper>
  );
}

type TableHeadersProps = {
  columns: Column[];
  sortDirection: SortDirection;
  sortField: Column;
  onChangeSortDirection: (direction: SortDirection) => void;
  onChangeSortField: (field: Column) => void;
};

function TableHeaders({
  columns,
  sortDirection,
  onChangeSortDirection,
  sortField,
  onChangeSortField,
}: TableHeadersProps) {
  const classes = useStyles();

  function clickHandler(field: Column) {
    return (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      if (field.name === sortField.name) {
        if (sortDirection === 'asc') {
          onChangeSortDirection('desc');
        } else {
          onChangeSortDirection('asc');
        }
      } else {
        onChangeSortField(field);
      }
    };
  }

  return (
    <>
      {columns.map((field, index) => (
        <TableCell
          key={field.name}
          className={classes.header}
          align={index === 0 ? undefined : 'right'}>
          <TableSortLabel
            active={sortField.name === field.name}
            direction={sortDirection}
            onClick={clickHandler(field)}>
            {field.label}
            {sortField.name === field.name ? (
              <span className={classes.visuallyHidden}>
                {sortDirection === 'desc'
                  ? 'sorted descending'
                  : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
      <TableCell key="spacer" className={classes.spacer}></TableCell>
    </>
  );
}

type TableRowsProps = {
  columns: Column[];
  rows: RowData[][];
};

function TableRows({ columns, rows }: TableRowsProps) {
  const classes = useStyles();
  return (
    <>
      {rows.map((row, index) => {
        const rowClassName = isOdd(index)
          ? classes.highlightedTableRow
          : classes.plainTableRow;
        return (
          <TableRow
            key={index}
            className={rowClassName}
            columns={columns}
            dataFields={row}
          />
        );
      })}
    </>
  );
}

type TableRowProps = {
  className: string;
  columns: Column[];
  dataFields: RowData[];
};

const FormatCell = ( column : Column , data : RowData , row : Record<string,RowData> ) : RowData => {
  if( column.formatValue ){
    return column.formatValue( data , row );
  }
  return data;
}
const ValidateCell = ( column : Column , data : RowData , row : Record<string,RowData> ) : boolean => {
  const regexTest = process.env[`REACT_APP_ERROR_REGEXP_${column.name.toUpperCase()}`];
  if( regexTest ){    
    return new RegExp(regexTest ).test( String( data ) );
  }
  if( column.validate ){
    return column.validate( data , row );
  }
  return true;
}


function TableRow({ className, columns, dataFields }: TableRowProps) {
  const classes = useStyles();

  function handleClick() {}

  const cellValues: string[] = [];
  const columnCount = columns.length;

  const row : Record<string, RowData> = columns.map( ( value , index ) => {
    return { [value.name ] : dataFields[index ]};
  }).reduce( ( cv , pv ) => { return { ...cv , ...pv }  }, {} );

  const valid : boolean[] = columns.map( ( column , index ) => {
    return ValidateCell( column , dataFields[index] , row );
  })
  for (let i = 0; i < columnCount; i++) {
    const column = columns[i];
    const dataField = FormatCell( column , dataFields[i] , row );
    switch (typeof dataField) {
      case 'undefined':
      case 'object':
        cellValues.push('N/A');
        break;
      case 'number':
      case 'boolean':
        cellValues.push(String(dataField));
        break;
      default:
        cellValues.push(dataField);
        break;
    }
  }

  const [firstField, ...otherFields] = cellValues;
  const cell1 : { className? : string } = {};
  if(!valid[0]){
    cell1.className = classes.cellError;
  }
  const rowValid = valid.reduce( (cv , pv ) => cv && pv , true );
  const rowClases : string[] = [className];
  if( !rowValid ){
    rowClases.push(classes.rowError);
  }
  return (
    <MuiTableRow className={rowClases.reverse().join(' ')} onClick={handleClick}>
      <TableCell {...cell1} component="th" scope="row">
        {firstField}
      </TableCell>
      {otherFields.map((value, index) => {
        const c : { className? : string } = {};
        if(!valid[index + 1 ]){
          c.className = classes.cellError;
        }
        return (        
        <TableCell {...c} key={index} align="right">
          {value}
        </TableCell>
      ) } )}
      <TableCell></TableCell>
    </MuiTableRow>
  );
}

function formatMilliseconds(time: number) {
  const duration = getDuration(time);
  const chunks = duration.split(', ');
  if (chunks.length > 2) {
    return `${chunks[0]}, ${chunks[1]}`;
  }
  return duration;
}
