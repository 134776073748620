export interface IUnitType {
  label: string;
  mod: number;
  display?: boolean;
}

export function getDuration(millis: number) {
  if (millis === 0) {
    return '0 seconds';
  }

  const dur = {} as Record<string, any>;
  const units: IUnitType[] = [
    { label: 'millis', mod: 1000, display: false },
    { label: 'seconds', mod: 60 },
    { label: 'minutes', mod: 60 },
    { label: 'hours', mod: 24 },
    { label: 'days', mod: 31 },
  ];

  // calculate the individual unit values...
  units.forEach((u) => {
    millis = (millis - (dur[u.label] = millis % u.mod)) / u.mod;
  });

  // convert object to a string representation...
  const nonZero = (u: IUnitType) => {
    return dur[u.label];
  };

  const relevantDurations = units.reverse().filter(nonZero);

  return relevantDurations
    .map((u) => {
      if (u.display === false) {
        if (relevantDurations.length === 1 && u.label === 'millis') {
          return '< 1 second';
        }
        return 'IGNORE';
      }
      return (
        dur[u.label] +
        ' ' +
        (dur[u.label] === 1 ? u.label.slice(0, -1) : u.label)
      );
    })
    .filter((t) => {
      return t !== 'IGNORE';
    })
    .join(', ');
}
