import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import React, { useContext, useEffect, useState } from 'react';
import { User } from '../types';
import { errorLog } from '../utils';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export type AuthContext = {
  isAuthenticated: boolean;
  user: User;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: (params?: PopupLoginOptions) => Promise<void>;
  handleRedirectCallback: () => void;
  getIdTokenClaims: (
    options?: getIdTokenClaimsOptions,
  ) => Promise<IdToken> | undefined;
  loginWithRedirect: (
    options?: RedirectLoginOptions,
  ) => Promise<void> | undefined;
  getTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string> | undefined;
  getTokenWithPopup: (
    options?: GetTokenWithPopupOptions,
  ) => Promise<string> | undefined;
  logout: (options?: LogoutOptions) => void;
};

export const AuthContext = React.createContext<AuthContext>({} as any);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = () => {};

export type AuthProps = {
  children?: React.ReactNode;
  onRedirectCallback?: (appState?: any) => void;
} & Auth0ClientOptions;

export default function Auth({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: AuthProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };

    initAuth0();
  }, []);

  const loginWithPopup = async (params: PopupLoginOptions = {}) => {
    if (!auth0Client) {
      return;
    }

    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      errorLog(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    if (!auth0Client) {
      return;
    }

    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: user as User,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (options?: getIdTokenClaimsOptions) =>
          auth0Client ? auth0Client.getIdTokenClaims(options) : undefined,

        loginWithRedirect: (options?: RedirectLoginOptions) =>
          auth0Client ? auth0Client.loginWithRedirect(options) : undefined,

        getTokenSilently: (options?: GetTokenSilentlyOptions) =>
          auth0Client ? auth0Client.getTokenSilently(options) : undefined,

        getTokenWithPopup: (options?: GetTokenWithPopupOptions) =>
          auth0Client ? auth0Client.getTokenWithPopup(options) : undefined,

        logout: (options?: LogoutOptions) =>
          auth0Client ? auth0Client.logout(options) : undefined,
      }}>
      {children}
    </AuthContext.Provider>
  );
}
