import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAuth } from '../../auth';
import { searchConnections } from '../../services';
import api from '../../services/api';
import {
  Connection,
  SearchFn,
  SearchOptions,
  SortDirection,
} from '../../types';
import { emptySearchResponse, likeFilter } from '../../utils';
import Table, { Column, RowData } from '../table';

const columns: Column[] = [
  { name: 'appointment_id', label: 'Appt. ID', type: 'string' },
  { name: 'created', label: 'Date', type: 'string' },
  { name: 'os', label: 'OS', type: 'string' },
  { name: 'browser', label: 'Browser', type: 'string' },
  { name: 'has_errors', label: 'Errors', type: 'boolean' , validate : ( input ) => !Boolean( input) },
  { name: 'ip', label: 'Ip', type: 'string' },
  { name: 'user', label: 'Type', type: 'string' , formatValue : ( input ) => {
    return input === "" ? 'Patient' : 'Provider';
  } },
];

function useDebounce<T extends any = any>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}

export type AppointmentSearchProps = {};

export default function AppointmentSearch() {
  const auth = useAuth();

  const [total, setTotal] = useState(0);

  const [appointmentId, setAppointmentId] = useState('');

  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    pagination: { page: 1, size: 25 },
  });

  const searchOptionsUpdate = useDebounce(searchOptions, 300);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [sortField, setSortField] = useState<Column>(columns[1]);

  const [rows, setRows] = useState<RowData[][]>([]);
  const [search, setSearch] = useState<SearchFn<Connection>>(() => async () =>
    emptySearchResponse<Connection>(),
  );

  useEffect(() => {
    auth.getTokenSilently()?.then((token) => {
      if (token) {
        setSearch(() => searchConnections(api({ token })));
      }
    });
  }, [auth]);

  useEffect(() => {
    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: 'created', direction: 'desc' },
      },
      appointmentId,
    );

    search(options).then(({ total, results }) => {
      const rows = resultsToRows(results);
      setTotal(total);
      setRows(rows);
    });
  }, [appointmentId, currentPage, rowsPerPage, search]);

  useEffect(() => {
    search(searchOptions).then(({ count, total, results }) => {
      if (rowsPerPage > total) {
        setCurrentPage(0);
      }

      const rows = resultsToRows(results);
      setRows(rows);

      if (count > total) {
        setTotal(0);
      } else {
        setTotal(total);
      }
    });
  }, [rowsPerPage, search, searchOptions, searchOptionsUpdate]);

  async function handleAppointmentIdChange(
    event: ChangeEvent<HTMLInputElement>,
  ) {
    event.preventDefault();
    const appointmentId = String(event.target.value);

    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
    );

    setAppointmentId(appointmentId);
    setSearchOptions(options);
  }

  async function handleChangeCurrentPage(currentPage: number) {
    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
    );

    setCurrentPage(currentPage);
    setSearchOptions(options);
  }

  async function handleChangeRowsPerPage(rowsPerPage: number) {
    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
    );

    setRowsPerPage(rowsPerPage);
    setSearchOptions(options);
  }

  async function handleChangeSortDirection(sortDirection: SortDirection) {
    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
    );

    setSortDirection(sortDirection);
    setSearchOptions(options);
  }

  async function handleChangeSortField(sortField: Column) {
    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: 'desc' },
      },
      appointmentId,
    );

    setSortField(sortField);
    setSortDirection('desc');
    setSearchOptions(options);
  }

  return (
    <>
      <Box m={3}>
        <TextField
          label="Appointment ID"
          variant="filled"
          value={appointmentId}
          onChange={handleAppointmentIdChange}
        />
      </Box>
      <Table
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        onChangeCurrentPage={handleChangeCurrentPage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        sortDirection={sortDirection}
        onChangeSortDirection={handleChangeSortDirection}
        sortField={sortField}
        onChangeSortField={handleChangeSortField}
      />
    </>
  );
}

function resultsToRows(results: Connection[]) {
  return results.map((data: any) => {
    const row = [];
    for (const column of columns) {
      const value = data[column.name];
      if (valueIsDate(column, value)) {
        row.push(new Date(value).toLocaleString());
      } else {
        row.push(value);
      }
    }
    return row as RowData[];
  });
}

function valueIsDate(column: Column, value: any): value is string {
  return column.name === 'created';
}

function setAppointmentFilter(options: SearchOptions, appointmentId: string) {
  if (!appointmentId) {
    return options;
  }

  options.filter = {
    appointment_id: likeFilter(appointmentId),
  };

  return options;
}
