import LuxonUtils from '@date-io/luxon';
import { red } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import createMuiTheme, {
  Theme,
  ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider, { useAuth } from '../../auth';
import history from '../../utils/history';
import RippleLoader from '../utils/ripple-loader';
import './app.scss';
import Header from './header';
import Main from './main';

function onRedirectCallback(appState: any) {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
}

function createTheme(options?: ThemeOptions): Theme {
  const newTheme = createMuiTheme(
    {
      palette: {
        primary: { main: '#FFFFFF' },
        secondary: { main: '#2196f3', light: '#99cefa' },
        error: { main: red.A400 },
        background: { default: '#FFFFFF' },
      },
      mixins: {
        toolbar: {
          fontSize: 'calc(1rem + 2vmin)',
          // minHeight: '86px',
        },
      },
    },
    options || {},
  );

  return responsiveFontSizes(newTheme);
}

export default function App() {
  const context = {
    theme: createTheme(),
    activeListItem: 'DefaultView',
    isReady: false,
  };

  return (
    <AuthProvider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ''}
      client_id={process.env.REACT_APP_AUTH_CLIENT_ID || ''}
      redirect_uri={window.location.origin}
      audience={process.env.REACT_APP_AUTH_AUDIENCE || ''}
      onRedirectCallback={onRedirectCallback}>
      <ThemeProvider theme={context.theme}>
        <CssBaseline />
        <IconContext.Provider
          value={{ color: context.theme.palette.secondary.main, size: '2em' }}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <AppContent />
          </MuiPickersUtilsProvider>
        </IconContext.Provider>
      </ThemeProvider>
    </AuthProvider>
  );
}

function AppContent() {
  const { loading } = useAuth();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setReady(true);
      }, 500);
    }
  }, [loading]);

  if (loading || !ready) {
    return (
      <div className="app-loader">
        <RippleLoader color="secondary" />
      </div>
    );
  }

  return (
    <Router>
      <Header />
      <main className="app-content">
        <Main />
      </main>
    </Router>
  );
}
