import {
  ApiResponse,
  Connection,
  DataApiResponse,
  ErrorApiResponse,
  SearchOptions,
  SearchResponse,
} from '../types';
import { emptySearchResponse, sortValue } from '../utils';
import { Api } from './api';

/**
 * Retrieve a list of zero or more connections
 */
export const searchConnections = searchFor<Connection>('connections');

function searchFor<T>(resource: string) {
  return (api: Api) => (options: SearchOptions): Promise<SearchResponse<T>> =>
    api
      .get<SearchResponse<T>>(resource, {
        page: {
          number: options.pagination.page,
          size: options.pagination.size,
        },
        filter: options.filter
          ? {
              ...options.filter,
            }
          : undefined,
        sort: options.sort
          ? sortValue(options.sort.field, options.sort.direction)
          : undefined,
      })
      .then((res) => res ?? emptySearchResponse<T>());
}

/* ~~~ Utils ~~~ */

/* ~~~ Type Guards ~~~ */

function isDataResponse(res: ApiResponse): res is DataApiResponse {
  return res && res.data && typeof res.data === 'object';
}

function isErrorResponse(res: ApiResponse): res is ErrorApiResponse {
  return res && Array.isArray(res.errors);
}
